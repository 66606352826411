import React from 'react'
import styled from 'styled-components'
import tw from 'tailwind.macro'
import { Parallax } from 'react-spring/renderprops-addons.cjs'

// Components
import Layout from '../components/Layout'
import ProjectCard from '../components/ProjectCard'

// Elements
import Inner from '../elements/Inner'
import { Title, BigTitle, Subtitle } from '../elements/Titles'

// Views
import Hero from '../views/Hero'
import Projects from '../views/Projects'
import About from '../views/About'
import Contact from '../views/Contact'

import avatar from '../images/profile_head.png'

const ProjectsWrapper = styled.div`
  ${tw`flex flex-wrap justify-between mt-8`};
  display: grid;
  grid-gap: 4rem;
  grid-template-columns: repeat(2, 1fr);
  @media (max-width: 1200px) {
    grid-gap: 3rem;
  }
  @media (max-width: 900px) {
    grid-template-columns: 1fr;
    grid-gap: 2rem;
  }
`

const AboutHero = styled.div`
  ${tw`flex flex-col lg:flex-row items-center mt-8`};
`

const Avatar = styled.img`
  ${tw`rounded-full w-32 xl:w-48 shadow-lg h-auto`};
`

const AboutSub = styled.span`
  ${tw`text-white pt-12 lg:pt-0 lg:pl-12 text-2xl lg:text-3xl xl:text-4xl`};
`;

const AboutDesc = styled.p`
  ${tw`text-grey-light text-lg md:text-xl lg:text-2xl font-sans pt-6 md:pt-12 text-justify`};
`;

const ContactText = styled.p`
  ${tw`text-grey-light font-sans text-xl md:text-2xl lg:text-3xl`};
`;

const Footer = styled.footer`
  ${tw`text-center text-grey absolute pin-b p-6 font-sans text-md lg:text-lg`};
`;


const Index = () => (
  <>
    <Layout />
    <Parallax pages={5}>
      <Hero offset={0}>
        <BigTitle>
          Kai Schwanke<br /> Consulting
        </BigTitle>
        <Subtitle>Java consultant with more than 15 years of commercial experience, specialising in SAP Hybris e-Commerce solutions.</Subtitle>
      </Hero>
      <Projects offset={1}>
        <Title>Expertise</Title>
        <ProjectsWrapper>
          <ProjectCard title="Custom Hybris implementations">
            Hybris
          </ProjectCard>
          <ProjectCard
            title="e-Commerce,
            Finance,
            Insurance"
            bg="linear-gradient(to right, #662D8C 0%, #ED1E79 100%)"
          >
            Industries
          </ProjectCard>
          <ProjectCard
            title="Java, Angular, React, Gatsby"
            bg="linear-gradient(to right, #009245 0%, #FCEE21 100%)"
          >
            Key skills
          </ProjectCard>
          <ProjectCard
            title="Customer focused
            "
            bg="linear-gradient(to right, #D585FF 0%, #00FFEE 100%)"
          >
            Business outcome driven
          </ProjectCard>
        </ProjectsWrapper>
      </Projects>
      <About offset={3}>
        <Title>About</Title>
        <AboutHero>
          <Avatar src={avatar} alt="About" />
          <AboutSub>
            I'm a Sydney-based Hybris/Java developer with over 15 years experience in multiple industries.
            My passion is delivering high-quality business solutions that enhance customer experience.
            Let's discuss your business opportunities and work together to achieve the best outcome for your company and your customers.
          </AboutSub>
        </AboutHero>
        <AboutDesc>
          <ContactText>
            <a href="mailto:kai.schwanke@gmail.com">Email </a> or <a href="tel:+61437186297"> call</a> me, or find me on :{' '}
            <a href="https://www.linkedin.com/in/kaischwanke/" target="_blank">LinkedIn</a> or{' '}
            <a href="https://www.xing.com/profile/Kai_Schwanke" target="_blank">Xing</a>
          </ContactText>
          <div id="pph-hireme"></div>
        </AboutDesc>
      </About>
      <Contact offset={4}>
        {/*<Inner>
          <Title>Get in touch</Title>
        </Inner>*/}
        <Footer>
          &copy; 2019 by Gatsby Starter Portfolio Cara.{' '}
          <a href="https://www.lekoarts.de">LekoArts</a>.
        </Footer>
      </Contact>
    </Parallax>
  </>
)



export default Index
